<template>
  <div id="psychologicalLogin">
    <div class="loginbox" v-loading="loading">
      <div class="titlebox">
        <span>{{ shop_name || "" }}</span>
        <span>管理员后台</span>
      </div>

      <div class="inputbox" style="margin-bottom: 40px">
        <div class="label">登录账号</div>
        <el-input
          type="text"
          style="input"
          v-model="user_name"
          placeholder="请输入账号"
        ></el-input>
      </div>

      <div class="inputbox">
        <div class="label">登录密码</div>
        <el-input
          type="password"
          style="input"
          v-model="password"
          placeholder="请输入密码"
        ></el-input>
      </div>

      <el-button class="loginbtn" type="primary" @click="login">登录</el-button>
    </div>
  </div>
</template>

<script>
import { eltips, getUrlKey } from "@/util/util.js";
import { resetRouter } from "@/router/index.js";
import store from "@/store/index.js";
export default {
  name: "psychologicalLogin",
  data() {
    return {
      loading: false,
      shop_name: "",
      user_name: "", //用户名
      password: "", //密码
      type: "", //1、站点 2、商家
      guard_name: "", //应用名称
      shop_id: "",
      token: "",
    };
  },
  created() {
    if (getUrlKey("name")) {
      this.shop_name = getUrlKey("name");
      sessionStorage.setItem("shop_name", this.shop_name);
    }

    if (getUrlKey("shop_id")) {
      this.shop_id = getUrlKey("shop_id");
    }

    if (getUrlKey("guard_name")) {
      this.guard_name = getUrlKey("guard_name");
      sessionStorage.setItem("guardName", this.guard_name);
      sessionStorage.setItem("fenguan", 1);
    }
  },
  methods: {
    //登录
    login() {
      if (!this.user_name) {
        eltips("请输入账号", "error");
        return;
      }
      if (!this.password) {
        eltips("请输入密码", "error");
        return;
      }

      let data = {
        shop_id: this.shop_id,
        account: this.user_name,
        password: this.password,
      };

      this.loading = true;
      this.axios.post("/api/assessment/admin/login", data).then((res) => {
        if (res.code == 200) {
          sessionStorage.setItem("token", res.data.access_token);
          sessionStorage.removeItem("role_id");
          sessionStorage.removeItem("pluginroute");
          sessionStorage.removeItem("pluginid");
          this.getuserinfo();
        } else {
          this.loading = false;
          eltips(res.msg, "error");
        }
      });
    },
    //获取个人信息
    getuserinfo() {
      this.axios.get("/api/assessment/admin/admininfo").then((res) => {
        if (res.code == 200) {
          sessionStorage.setItem("userinfo", JSON.stringify(res.data));
          this.getmeuns();
        } else {
          this.loading = false;
          eltips(res.msg, "error");
        }
      });
    },
    //获取权限菜单
    getmeuns() {
      //   this.axios.get("/api/get_menu").then((res) => {
      resetRouter();
      this.$store.commit("routestatus", 0);
      sessionStorage.removeItem("routestext");
      sessionStorage.removeItem("routes");
      let model = [
        {
          app_level: 0,
          category_id: 0,
          component: "Layout",
          created_at: "2022-04-15 17:36:51",
          desc: null,
          guard_name: "api",
          icon: "ceping",
          id: 2137,
          is_apportion: 1,
          keep_alive: 0,
          layout: "1",
          name: "测评",
          parent_id: 0,
          path: "",
          project_app_id: 0,
          props: null,
          route: "",
          router_name: "measurementIndex",
          sort: 3,
          status: 1,
          type: 1,
          unitid: 33,
          updated_at: "2022-04-18 13:41:28",
          uri: null,
          vue_name: "measurementIndex",
          _child: [
            {
              app_level: 0,
              category_id: 0,
              component: "apps/evaluation/measurement/index.vue",
              created_at: "2022-04-18 13:42:13",
              desc: null,
              guard_name: "api",
              icon: null,
              id: 2144,
              is_apportion: 1,
              keep_alive: 0,
              layout: "1",
              name: "已完成",
              parent_id: 2137,
              path: "/measurement/index",
              project_app_id: 0,
              props: null,
              route: "",
              router_name: "measurementIndex",
              sort: 1,
              status: 1,
              type: 1,
              unitid: 33,
              updated_at: "2022-04-20 16:28:14",
              uri: null,
              vue_name: "measurementIndex",
              _child: [],
            },
            {
              app_level: 0,
              category_id: 0,
              component: "apps/evaluation/measurement/noIndex.vue",
              created_at: "2022-04-18 13:42:54",
              desc: null,
              guard_name: "api",
              icon: null,
              id: 2145,
              is_apportion: 1,
              keep_alive: 0,
              layout: "1",
              name: "未完成",
              parent_id: 2137,
              path: "/measurement/noIndex",
              project_app_id: 0,
              props: null,
              route: "",
              router_name: "measurementIndex",
              sort: 2,
              status: 1,
              type: 1,
              unitid: 33,
              updated_at: "2022-04-20 16:28:18",
              uri: null,
              vue_name: "measurementNoIndex",
              _child: [],
            },
          ],
        },
      ];
      sessionStorage.setItem("routes", JSON.stringify(model));
      eltips("登录成功", "success");
      this.loading = true;
      let path;

      if (model[0].path) {
        path = model[0].path;
      } else {
        let child = model[0]._child;
        for (let i = 0; i < child.length; i++) {
          if (child[i].path) {
            path = child[i].path;
            break;
          }
        }
      }
      this.$router.replace({ path: path });
      //   });
    },
  },
};
</script>


<style>
#psychologicalLogin .el-input__inner {
  border: none !important;
}

#psychologicalLogin .el-button {
  padding: 18px 20px !important;
  font-size: 16px;
  font-weight: bold;
}
</style>
<style scoped lang="less">
#psychologicalLogin {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: url(../../assets/images/login_bg.png);
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 4px 2px rgba(0, 0, 0, 0.1);
  .loginbox {
    width: 380px;
    background: #ffffff;
    border-radius: 20px;
    padding: 30px;
  }
  .titlebox {
    font-size: 20px;
    color: #1e92ff;
    margin-bottom: 40px;
    font-weight: bold;
  }
  .inputbox {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #dedede;
    padding-bottom: 6px;
    .label {
      width: 80px;
    }
    .input {
    }
  }
  .loginbtn {
    margin-top: 160px;
    width: 100%;
  }
}
</style>
